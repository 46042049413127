import { createRouter, createWebHistory } from "vue-router";
import { useUserStore } from "@/stores/user";
import SignInLayout from "@/layouts/SignInLayout.vue";
import AuthenticatedLayout from "@/layouts/AuthenticatedLayout.vue";
import WelcomeLayout from "@/layouts/WelcomeLayout.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/welcome",
      component: WelcomeLayout,
      meta: { requiresAuth: true },
      children: [
        {
          path: "about-us",
          name: "AboutUs",
          component: () => import("@/pages/welcome/AboutUs.vue"),
        },
        {
          path: "about-you",
          name: "AboutYou",
          component: () => import("@/pages/welcome/AboutYou.vue"),
        },
      ],
    },
    {
      path: "/",
      redirect: () => {
        const userStore = useUserStore();
        return userStore.isAuthenticated ? { name: "Dashboard" } : { name: "SignIn" };
      },
    },
    {
      path: "/",
      component: SignInLayout,
      children: [
        {
          path: "sign-in",
          name: "SignIn",
          component: () => import("@/pages/authentication/SignInPage.vue"),
        },
        {
          path: "sign-up",
          name: "SignUp",
          component: () => import("@/pages/authentication/SignUpPage.vue"),
        },
        {
          path: "forgot-password",
          name: "ForgotPassword",
          component: () => import("@/pages/authentication/ForgotPasswordPage.vue"),
        },
        {
          path: "reset-password/:resetToken",
          name: "ResetPassword",
          component: () => import("@/pages/authentication/ResetPasswordPage.vue"),
        },
        {
          path: "mfa-enrollment",
          name: "MfaEnrollment",
          component: () => import("@/pages/authentication/MfaEnrollmentPage.vue"),
          beforeEnter: (_to, from, next) => {
            const userStore = useUserStore();
            if (
              !userStore.isAuthenticated ||
              (!userStore.mfaEnrollmentAvailable && !userStore.mfaVerificationRequired)
            ) {
              next({ name: "SignIn" });
            } else {
              next();
            }
          },
        },
      ],
    },
    {
      path: "/",
      component: AuthenticatedLayout,
      meta: { requiresAuth: true },
      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          component: () => import("@/pages/dashboard/DashboardPage.vue"),
        },
        {
          path: "requests",
          name: "Requests",
          component: () => import("@/pages/requests/RequestsPage.vue"),
        },
        {
          path: "requests/:requestId",
          name: "Request",
          component: () => import("@/pages/requests/RequestPage.vue"),
        },
        {
          path: "requests/category/:categoryId",
          name: "RequestCategory",
          component: () => import("@/pages/requests/CategoryPage.vue"),
        },
      ],
    },
    {
      path: "/:pathMatch(.*)*",
      name: "NotFound",
      component: () => import("@/pages/error/NotFoundPage.vue"),
    },
  ],
});

function requiresAuthentication(to) {
  return to.matched?.some((route) => route.meta?.requiresAuth);
}

function shouldRedirectToSignIn(to) {
  const userStore = useUserStore();
  if (!userStore.isAuthenticated && requiresAuthentication(to) && to.name !== "SignIn") {
    return true;
  }
  return false;
}

router.beforeEach(async (to) => {
  const userStore = useUserStore();
  if (shouldRedirectToSignIn(to)) {
    sessionStorage.setItem("returnTo", to.fullPath);
    return { name: "SignIn" };
  } else if (
    userStore.isAuthenticated &&
    to.name === "SignIn" &&
    !userStore.mfaVerificationRequired &&
    !userStore.mfaEnrollmentAvailable
  ) {
    return { name: "Dashboard" };
  }
});

export default router;
