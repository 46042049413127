import { ref } from "vue";
import { defineStore } from "pinia";

export const useNotifications = defineStore("notifications", () => {
  const notifications = ref([]);

  function generateId() {
    return Math.random().toString(36).substring(2, 15);
  }

  function send(message, type, autoDismiss = false) {
    const id = generateId();
    notifications.value.push({ id, message, type });
    if (autoDismiss) {
      setTimeout(() => {
        dismiss(id);
      }, 10000);
    }
  }

  function dismiss(id) {
    notifications.value = notifications.value.filter(
      (notification) => notification.id !== id,
    );
  }

  return {
    notifications,
    send,
    dismiss,
  };
});
