<script setup>
import {
  StealieDrawer,
  StealieLogo,
  StealieText,
  StealieParagraph,
} from "@get-overalls/fe-stealie";
import { RouterLink } from "vue-router";
import { useNavigation } from "@/composables/useNavigation";

const { navigationItems, user, company, handleSignOut } = useNavigation();

const isOpen = defineModel("open", {
  type: Boolean,
  default: false,
});
</script>

<template>
  <StealieDrawer
    v-model:open="isOpen"
    variant="nav"
    position="left"
    full-bleed
    dismissible
    scrollable
  >
    <template #header>
      <div class="p-5 pt-6">
        <StealieLogo class="h-8" />
      </div>
    </template>
    <div class="flex h-full flex-1 flex-col gap-y-4 p-5">
      <nav>
        <ul
          class="flex flex-col items-stretch justify-between gap-y-2.5 font-semibold text-brand-gray-600"
        >
          <StealieText v-for="navItem in navigationItems" :key="navItem.label" as="li">
            <RouterLink
              :to="navItem.route"
              class="flex items-center justify-start gap-x-4 rounded-sm p-2 text-xl"
              exact-active-class="bg-brand-primary-teal-highlight font-bold text-brand-gray-600"
              @click="isOpen = false"
            >
              <component :is="navItem.icon" class="size-8 stroke-2" />
              {{ navItem.label }}
            </RouterLink>
          </StealieText>
        </ul>
      </nav>
      <section class="flex items-center gap-x-4 p-2">
        <div
          class="flex size-12 items-center justify-center rounded-full bg-brand-primary-teal-400 text-xl font-medium text-white"
        >
          {{ user.initials }}
        </div>
        <div class="flex flex-col items-start justify-between text-xl">
          <StealieText class="mr-auto font-semibold" size="lg">{{
            user.fullName
          }}</StealieText>
          <button
            class="cursor-pointer text-brand-primary-teal-400"
            @click="handleSignOut"
          >
            sign out
          </button>
        </div>
      </section>
      <footer
        v-if="company.name"
        class="flex flex-col items-center gap-y-3 rounded-lg border border-brand-gray-50 px-2 pt-3 pb-7"
      >
        <StealieParagraph class="text-xs text-brand-gray-400">
          Your access to Overalls is provided by:
        </StealieParagraph>
        <img v-if="company.logoUrl" :src="company.logoUrl" :alt="company.name" />
        <StealieParagraph
          v-else
          class="text-center text-xl font-semibold text-balance text-brand-gray-500"
        >
          {{ company.name }}
        </StealieParagraph>
      </footer>
    </div>
  </StealieDrawer>
</template>
