import { ref, computed } from "vue";
import { defineStore } from "pinia";
import * as AuthenticationService from "@/services/AuthenticationService";
import * as NavigationService from "@/services/NavigationService";
import httpClient from "@/services/httpClient";
import getInitials from "@/utilities/getInitials";

export const useUserStore = defineStore("user", () => {
  const token = ref(localStorage.getItem("token"));
  const tokenExpiresAt = ref(new Date(Number(localStorage.getItem("tokenExpiresAt"))));
  const mfaToken = ref(localStorage.getItem("mfaToken"));
  const mfaVerificationRequired = ref(false);
  const mfaEnrollmentAvailable = ref(false);
  const isAuthenticated = computed(() =>
    Boolean(token.value && tokenExpiresAt.value && tokenExpiresAt.value > new Date()),
  );
  const currentUser = ref(null);
  const initials = computed(() => getInitials(currentUser.value?.full_name));
  const user = computed(() =>
    currentUser.value ? { ...currentUser.value, initials: initials.value } : null,
  );
  setAuthorizationHeader();
  refreshCurrentUserState();

  function incrementRequestCount() {
    if (currentUser.value) {
      currentUser.value.request_count = (currentUser.value.request_count || 0) + 1;
    }
  }

  function setAuthorizationHeader() {
    if (isAuthenticated.value) {
      httpClient.defaults.headers.common["Authorization"] = token.value;
    }
    if (mfaToken.value) {
      httpClient.defaults.headers.common["mfa-token"] = mfaToken.value;
    }

    // redirect to sign in page if required authentication is missing
    httpClient.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status !== 401) {
          return Promise.reject(error);
        }
        if (error?.response?.data?.error === "MFA authentication required") {
          mfaVerificationRequired.value = true;
        } else if (!error?.request?.responseURL?.endsWith("/api/v1/mfa/verify")) {
          resetState();
          const returnPath =
            window.location.pathname + window.location.search + window.location.hash;
          NavigationService.redirectToSignIn(returnPath);
        }

        return Promise.reject(error);
      },
    );
  }

  function setLocalToken(newToken, newTokenExpiresAt) {
    token.value = newToken;
    localStorage.setItem("token", newToken);
    tokenExpiresAt.value = new Date(newTokenExpiresAt);
    localStorage.setItem("tokenExpiresAt", tokenExpiresAt.value.getTime());
    httpClient.defaults.headers.common["Authorization"] = newToken;
  }

  function removeLocalTokens() {
    token.value = null;
    tokenExpiresAt.value = null;
    mfaToken.value = null;
    delete httpClient.defaults.headers.common["Authorization"];
    delete httpClient.defaults.headers.common["mfa-token"];
    localStorage.clear();
  }

  function resetState() {
    removeLocalTokens();
    currentUser.value = null;
  }

  async function refreshCurrentUserState() {
    if (!isAuthenticated.value) {
      return;
    }

    try {
      const userData = await AuthenticationService.fetchCurrentUser();
      currentUser.value = userData;
    } catch (error) {
      // 401 errors are handled by the interceptor
      if (error.status !== 401) {
        throw error;
      }
    }
  }

  async function signUp(email, password, name, lastName) {
    try {
      const signUpResponse = await AuthenticationService.signUp(
        email,
        password,
        name,
        lastName,
      );

      await signIn(JSON.parse(signUpResponse.data.data).email, password);
      return { success: true };
    } catch (error) {
      if (error.response?.status === 401) {
        resetState();
      }
      return {
        success: false,
        error:
          error.response?.data?.error ||
          "Unable to sign in at this time. Please try again later.",
      };
    }
  }

  async function signIn(email, password) {
    try {
      const response = await AuthenticationService.signIn(email, password);
      const newToken = response?.headers?.authorization;
      const tokenExpiresAt = response?.data?.expires_at;
      if (!newToken) {
        throw new Error("No authorization token received");
      }
      setLocalToken(newToken, tokenExpiresAt);

      if (response?.data?.mfa_requires_verification) {
        mfaEnrollmentAvailable.value = true;
        return { success: true, mfaEnrollmentAvailable: true };
      } else if (response?.data?.mfa_required) {
        mfaVerificationRequired.value = true;
        return { success: true, mfaRequired: true };
      }

      await refreshCurrentUserState();
      return { success: true };
    } catch (error) {
      if (error.response?.status === 401) {
        resetState();
      }

      return {
        success: false,
        error:
          error.response?.data?.error ||
          "Unable to sign in at this time. Please try again later.",
      };
    }
  }

  function setLocalMfaToken(newMfaToken) {
    mfaToken.value = newMfaToken;
    mfaVerificationRequired.value = false;
    localStorage.setItem("mfaToken", newMfaToken);
    httpClient.defaults.headers.common["mfa-token"] = newMfaToken;
  }

  async function verifyMfaToken(mfaCode) {
    try {
      const response = await AuthenticationService.verifyMfaToken(mfaCode);

      if (!response?.data?.success) {
        return { success: false, error: response };
      }
      const newMfaToken = response?.headers?.["mfa-token"];
      if (!newMfaToken) {
        return {
          success: false,
          error: "No MFA verification token received",
        };
      }
      setLocalMfaToken(newMfaToken);
      mfaVerificationRequired.value = false;
      mfaEnrollmentAvailable.value = false;
      await refreshCurrentUserState();
      return { success: true };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Unable to verify MFA token",
      };
    }
  }

  async function signOut() {
    try {
      await AuthenticationService.signOut();
    } finally {
      resetState();
    }
  }

  async function requestPasswordReset(email) {
    try {
      const response = await AuthenticationService.requestPasswordReset(email);
      return { success: true, response };
    } catch (error) {
      return {
        success: false,
        error:
          error.response?.data?.error ||
          "Unable to reset password at this time. Please try again later.",
      };
    }
  }

  async function resetPassword(resetToken, password, passwordConfirmation) {
    try {
      const response = await AuthenticationService.resetPassword(
        resetToken,
        password,
        passwordConfirmation,
      );
      return { success: true, response };
    } catch (error) {
      return {
        success: false,
        error:
          error.response?.data?.error ||
          "Unable to reset password at this time. Please try again later.",
      };
    }
  }

  async function requestMfaToken() {
    try {
      await AuthenticationService.requestMfaToken();
      return { success: true };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Unable to request MFA token at this time.",
      };
    }
  }

  async function enrollMfa(mfaPhoneNumber) {
    try {
      await AuthenticationService.enrollMfa(mfaPhoneNumber);
      mfaVerificationRequired.value = true;
      return { success: true };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Unable to enroll in MFA at this time.",
      };
    }
  }

  return {
    isAuthenticated,
    user,
    signIn,
    signOut,
    signUp,
    _currentUser: currentUser,
    requestPasswordReset,
    resetPassword,
    requestMfaToken,
    verifyMfaToken,
    mfaVerificationRequired,
    mfaEnrollmentAvailable,
    enrollMfa,
    incrementRequestCount,
  };
});
