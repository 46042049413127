<script setup>
import { ref, computed } from "vue";
import { StealieLogo } from "@get-overalls/fe-stealie";
import { RouterView } from "vue-router";

const progressStep = ref(0);

const progressClass = computed(
  () => ["w-[50%]", "w-full"][Math.abs(progressStep.value - 1)],
);

const handleProgressUpdate = (step) => {
  progressStep.value = step;
};
</script>

<template>
  <header
    class="sticky top-0 z-10 flex flex-col items-center gap-x-40 gap-y-8 bg-white px-10 pt-8 pb-8 lg:flex-row"
  >
    <StealieLogo class="h-8" />
    <div class="w-full">
      <div class="h-2 w-full rounded-full bg-[#D9D9D9]">
        <div
          :class="[
            'h-2 rounded-full bg-brand-primary-teal-400 motion-safe:transition-[width] motion-safe:duration-300',
            progressClass,
          ]"
        >
          <span class="sr-only">step {{ progressStep }} of 3</span>
        </div>
      </div>
    </div>
  </header>
  <main
    class="mx-4 my-6 rounded-sm bg-white px-4 py-8 shadow-[0px_1px_4px_0px_rgba(0,0,0,0.10)] md:mx-8 md:my-10 md:p-10"
  >
    <RouterView @update-progress="handleProgressUpdate" />
  </main>
</template>
