<script setup>
import { RouterView } from "vue-router";
import { StealieLogo } from "@get-overalls/fe-stealie";
import { computed } from "vue";
import { useRoute } from "vue-router";
const route = useRoute();
const illustrationUrl = computed(() => {
  switch (route?.name) {
    case "ForgotPassword":
    case "ResetPassword":
      return "/illustrations/Character01.svg";
    case "SignIn":
    default:
      return "/illustrations/Character02.svg";
  }
});

const quotes = [
  "You help me do things that I want done but that I wouldn't otherwise do.",
  "Overalls completed a task in days that has taken me over a year to get nowhere on.",
  "Can't get over how great my first experience with Overalls was!",
  "Overalls completed a task in days that has taken me over a year to get nowhere on.",
  "As I say every time: this benefit is my favorite thing that my employer provides!",
  "Another knock out of the park save in time!",
  "A huge help and saved me so much time!",
  "This really reduces my daily stress!",
  "It's not just the physical time saved but the mental knowledge that you are moving forward even when you feel like you are stretched thin.",
  "So easy. Saved me tons of time and provided me with exactly what I was looking for!",
  "Extremely good detail and research on a fairly difficult request.",
];

const users = [
  "a satisfied overalls user",
  "a relaxed overalls user",
  "another happy overalls user",
  "an overalls user with one less thing to do",
  "an overalls user with a dwindling to-do list",
];

const randomQuote = computed(() => {
  const randomIndex = Math.floor(Math.random() * quotes.length);
  return quotes[randomIndex];
});

const randomUser = computed(() => {
  const randomIndex = Math.floor(Math.random() * users.length);
  return users[randomIndex];
});
</script>

<template>
  <header
    class="fixed top-0 right-0 left-0 z-10 bg-white px-8 py-6 shadow-[0_4px_8px_rgba(10,92,94,0.08)] sm:right-auto sm:bg-transparent sm:py-8 sm:shadow-none"
  >
    <StealieLink :to="{ name: 'SignIn' }">
      <StealieLogo class="h-7 sm:h-10" />
    </StealieLink>
  </header>
  <div class="flex w-screen flex-col sm:fixed sm:h-screen sm:flex-row">
    <section
      class="hidden items-center justify-center bg-brand-primary-teal-50 sm:order-none sm:flex sm:flex-1 sm:overflow-auto"
    >
      <figure class="my-16 flex flex-col items-center">
        <img :src="illustrationUrl" alt="" />
        <figcaption class="mt-6 w-72 text-center">
          <blockquote class="mb-2">
            <StealieText bold class="block">
              {{ randomQuote }}
            </StealieText>
          </blockquote>
          <StealieText bold size="sm" class="text-brand-primary-teal-400 uppercase">
            – {{ randomUser }}
          </StealieText>
        </figcaption>
      </figure>
    </section>
    <section
      class="flex items-center justify-center overflow-auto bg-white px-12 pt-32 sm:flex-1 sm:pt-0"
    >
      <main class="w-full max-w-sm">
        <RouterView />
      </main>
    </section>
  </div>
</template>
