import "./tailwind.css";

import { createApp } from "vue";
import { createPinia } from "pinia";
import Stealie from "@get-overalls/fe-stealie";
import * as Sentry from "@sentry/vue";

import App from "./App.vue";
import router from "./router";
import { setRouter } from "@/services/NavigationService";

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://c01744fd14a87f585264c4575dcd871e@o1081935.ingest.us.sentry.io/4508452060266496",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // eslint-disable-next-line no-undef
  enabled: process.env.NODE_ENV === "production",
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/refresh\.overalls\.ai/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(Stealie);
app.use(createPinia());
app.use(router);

setRouter(router);

app.mount("#app");
