import httpClient from "@/services/httpClient";

export const signIn = async (email, password) => {
  const response = await httpClient.post("/api/v1/users/sign_in", {
    user: { email, password },
  });

  return response;
};

export const signUp = async (email, password, name, last_name, terms_of_service = 1) => {
  const response = await httpClient.post("/api/v1/users/sign_up", {
    user: {
      email,
      password,
      name,
      last_name,
      terms_of_service,
      company_slug: "Transform",
    },
  });

  return response;
};

export const signOut = async () => {
  const response = await httpClient.delete("/api/v1/users/sign_out");
  return response;
};

export const requestPasswordReset = async (email) => {
  const response = await httpClient.post("/api/v1/users/password", {
    user: { email },
  });
  return response;
};

export const resetPassword = async (resetToken, password, passwordConfirmation) => {
  const response = await httpClient.put("/api/v1/users/password", {
    reset_password_token: resetToken,
    password,
    password_confirmation: passwordConfirmation,
  });
  return response;
};

export const fetchCurrentUser = async () => {
  const response = await httpClient.get("/api/v1/users/current");
  if (response?.status === 200) {
    return response.data;
  } else {
    return null;
  }
};

export const requestMfaToken = async () => {
  const response = await httpClient.post("/api/v1/mfa/send-otp");
  return response;
};

export const verifyMfaToken = async (otp) => {
  const response = await httpClient.post("/api/v1/mfa/verify", {
    otp,
  });
  return response;
};

export const enrollMfa = async (mfaPhoneNumber) => {
  const response = await httpClient.post("/api/v1/mfa", {
    mfa_number: mfaPhoneNumber,
  });
  return response;
};

export default {
  signIn,
  fetchCurrentUser,
  requestMfaToken,
  verifyMfaToken,
  enrollMfa,
};
