export default function (fullName) {
  if (!fullName || typeof fullName !== "string") return "";

  const names = fullName.toUpperCase().split(" ");

  if (names.length === 1) {
    return names[0].charAt(0);
  }

  return names[0].charAt(0) + names[names.length - 1].charAt(0);
}
