import axios from "axios";

if (!import.meta.env.VITE_OVERALLS_BASE_URL) {
  throw new Error("`VITE_OVERALLS_BASE_URL` is not set");
}

let baseUrl = import.meta.env.VITE_OVERALLS_BASE_URL;

if (
  window?.location?.origin !== "https://transform.overalls.ai" &&
  window?.location?.origin !== "https://refresh.overalls.ai" &&
  import.meta.env.VITE_OVERALLS_BASE_URL === "https://overalls.ai" &&
  import.meta.env.VITE_DEV_ON_PRODUCTION !== "true"
) {
  console.warn(
    "You appear to be running a non-production deployment but attempting to make requests to the production API.\n" +
      "THIS IS NOT ALLOWED.\n" +
      "All API requests will be routed to http://localhost:3000, or you can set a custom `VITE_OVERALLS_BASE_URL` variable in `.env.local`.\n\n" +
      "If you REALLY need to test against the production API, please set `VITE_DEV_ON_PRODUCTION` to `true` and rerun this app.",
  );
  baseUrl = "http://localhost:3000";
}

const httpClient = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default httpClient;
