<script setup>
import { StealieLogo, StealieText, StealieParagraph } from "@get-overalls/fe-stealie";
import { RouterLink } from "vue-router";
import { useNavigation } from "@/composables/useNavigation";

const { navigationItems, user, company, handleSignOut } = useNavigation();
</script>

<template>
  <section
    class="hidden h-full w-[275px] shrink-0 overflow-y-auto bg-white lg:flex lg:flex-col"
  >
    <header class="sticky top-0 px-4 py-8">
      <StealieLogo class="h-8" />
    </header>
    <div class="scrollbox flex h-full flex-1 flex-col gap-y-4 p-4">
      <nav>
        <ul
          class="flex flex-col items-stretch justify-between gap-y-2.5 font-semibold text-brand-gray-600"
        >
          <StealieText v-for="navItem in navigationItems" :key="navItem.label" as="li">
            <RouterLink
              :to="navItem.route"
              class="flex items-center justify-start gap-x-2 rounded-sm p-2"
              active-class="bg-brand-primary-teal-highlight font-bold text-brand-gray-600"
            >
              <component :is="navItem.icon" class="size-6 stroke-2" />
              {{ navItem.label }}
            </RouterLink>
          </StealieText>
        </ul>
      </nav>
      <section class="flex gap-x-2 p-2">
        <div
          class="flex size-8 items-center justify-center rounded-full bg-brand-primary-teal-400 text-sm text-white"
        >
          {{ user.initials }}
        </div>
        <div class="flex flex-col items-start gap-y-0.5">
          <StealieText>{{ user.fullName }}</StealieText>
          <button
            class="cursor-pointer text-brand-primary-teal-400"
            @click="handleSignOut"
          >
            sign out
          </button>
        </div>
      </section>
      <footer
        v-if="company.name"
        class="flex flex-col items-center gap-y-3 rounded-lg border border-brand-gray-50 px-2 pt-3 pb-7"
      >
        <StealieParagraph class="text-center text-xs text-balance text-brand-gray-400">
          Your access to Overalls is provided by:
        </StealieParagraph>
        <img v-if="company.logoUrl" :src="company.logoUrl" :alt="company.name" />
        <StealieParagraph
          v-else
          class="text-center text-xl font-semibold text-balance text-brand-gray-500"
        >
          {{ company.name }}
        </StealieParagraph>
      </footer>
    </div>
  </section>
</template>
