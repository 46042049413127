<script setup>
import { ref } from "vue";
import { RouterView } from "vue-router";
import { storeToRefs } from "pinia";
import { Bars3Icon } from "@heroicons/vue/24/outline";
import { useUserStore } from "@/stores/user";
import { StealieLogo } from "@get-overalls/fe-stealie";
import InitialsAvatar from "@/components/InitialsAvatar.vue";
import PrimaryNavigation from "@/components/PrimaryNavigation.vue";
import MobileNavigation from "@/components/MobileNavigation.vue";
import CopyrightFooter from "@/components/CopyrightFooter.vue";
const userStore = useUserStore();
const { user } = storeToRefs(userStore);
const isOpen = ref(false);
const toggleDrawer = () => {
  isOpen.value = !isOpen.value;
};
</script>

<template>
  <div class="fixed inset-0 flex">
    <MobileNavigation v-model:open="isOpen" />
    <PrimaryNavigation />
    <div class="flex flex-1 flex-col overflow-y-auto">
      <header
        class="sticky top-0 z-10 flex h-16 shrink-0 items-center bg-white px-4 shadow-[0px_4px_8px_0px_rgba(10,92,94,0.08)] lg:hidden"
      >
        <button class="rounded p-2" @click="toggleDrawer()">
          <Bars3Icon class="size-6 text-brand-primary-teal-400" />
        </button>
        <StealieLogo class="mr-auto h-7" />
        <InitialsAvatar v-if="user?.initials" :initials="user?.initials" />
      </header>
      <main class="flex h-full flex-1 flex-col p-2 sm:p-8 sm:pb-0">
        <Suspense>
          <RouterView />
        </Suspense>
        <CopyrightFooter />
      </main>
    </div>
  </div>
</template>
