import { HomeIcon, SparklesIcon } from "@heroicons/vue/24/outline";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { computed } from "vue";

export function useNavigation() {
  const userStore = useUserStore();
  const { user } = storeToRefs(userStore);
  const router = useRouter();

  const userData = computed(() => ({
    initials: user.value?.initials,
    fullName: user.value?.full_name,
  }));

  const company = computed(() => ({
    name: user.value?.company?.name,
    logoUrl: user.value?.company?.image_url?.replace("placehold", "place-hold"),
  }));

  const navigationItems = [
    {
      label: "Dashboard",
      icon: HomeIcon,
      route: { name: "Dashboard" },
    },
    {
      label: "Requests",
      icon: SparklesIcon,
      route: { name: "Requests" },
    },
  ];

  const handleSignOut = async () => {
    await userStore.signOut();
    router.push({ name: "SignIn" });
  };

  return {
    navigationItems,
    user: userData,
    company,
    handleSignOut,
  };
}
