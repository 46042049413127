<script setup>
import { computed } from "vue";
import { StealieParagraph } from "@get-overalls/fe-stealie";

const year = computed(() => new Date().getFullYear());
</script>

<template>
  <StealieParagraph
    class="text-brand-tertiary-teal-400 mt-2 mb-1 text-center text-xs md:ml-0.5 md:text-left"
  >
    © Copyright {{ year }} Overalls Inc. All rights reserved.
  </StealieParagraph>
</template>
