<script setup>
import { CheckCircleIcon } from "@heroicons/vue/24/outline";
import { XMarkIcon } from "@heroicons/vue/20/solid";
import { useNotifications } from "@/stores/notifications";

const notifications = useNotifications();
window.notifications = notifications;
</script>

<template>
  <div
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
  >
    <div role="alert" class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <TransitionGroup
        enter-active-class="transform ease-out duration-300 transition-all"
        enter-from-class="translate-y-8 opacity-0 sm:translate-y-0 sm:translate-x-full"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition-all ease-in duration-500"
        leave-from-class="opacity-100"
        leave-to-class="translate-y-8 opacity-0 sm:translate-y-0 sm:translate-x-full"
      >
        <div
          v-for="notification in notifications.notifications"
          :key="notification.id"
          class="pointer-events-auto w-full max-w-md overflow-hidden rounded-lg bg-brand-primary-teal-300 ring-1 shadow-lg ring-black/5"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="shrink-0">
                <CheckCircleIcon
                  class="size-6 text-brand-primary-teal-100"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p
                  class="text-sm font-medium text-balance whitespace-pre-line text-white"
                >
                  {{ notification.message }}
                </p>
              </div>
              <div class="ml-4 flex shrink-0">
                <button
                  type="button"
                  class="inline-flex rounded-md text-brand-primary-teal-100 hover:text-brand-primary-teal-50 focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2 focus-visible:outline-none"
                  @click="notifications.dismiss(notification.id)"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="size-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </TransitionGroup>
    </div>
  </div>
</template>
