let router = null;

export function setRouter(vueRouter) {
  router = vueRouter;
}

export function redirectToSignIn(returnPath) {
  if (!router) {
    throw new Error("Router not set");
  }

  if (returnPath) {
    sessionStorage.setItem("returnTo", returnPath);
  }
  router.push({ name: "SignIn" });
}
